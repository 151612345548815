@font-face {
  font-family: 'pxplus_ibm_vga9regular';
  src: url("font/pxplus_ibm_vga9-webfont.woff2") format("woff2"), url("font/pxplus_ibm_vga9-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
body,
html {
  background-color: blue;
  color: yellow;
  width: 100%;
  height: 100vh;
  margin: 0;
  padding: 0;
  overflow: hidden;
  font-family: 'pxplus_ibm_vga9regular';
}

.wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  margin: 0;
  padding: 10px;
  box-sizing: border-box;
}
.wrapper h1 {
  text-align: center;
  color: white;
  font-size: 1.875em;
  font-weight: normal;
}
.wrapper h1 a {
  text-decoration: none;
  color: white;
}
.wrapper h1 a:hover {
  text-decoration: underline;
}
.wrapper .container {
  width: 100%;
  height: 90vh;
  border: 2px solid white;
  outline: 2px solid white;
  outline-offset: 2px;
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
  font-size: 1.875em;
}
.wrapper .container a {
  color: yellow;
}
.wrapper .container .left,
.wrapper .container .right {
  width: 50%;
  height: 70%;
  display: flex;
  align-items: center;
  line-height: normal;
  padding-left: 5%;
}
.wrapper .container .left ul,
.wrapper .container .right ul {
  padding-left: 0;
}
.wrapper .container .left ul li,
.wrapper .container .right ul li {
  padding: 20px 15px;
}
.wrapper .container .left ul li a,
.wrapper .container .right ul li a {
  text-decoration: none;
}
.wrapper .container .left ul li:hover,
.wrapper .container .right ul li:hover {
  background-color: rgba(255, 255, 0, 0.1);
}
.wrapper .container .left {
  border-right: 1px solid white;
  box-sizing: border-box;
}
.wrapper .container .left ul {
  list-style: none;
}
.wrapper .container .left ul li::before {
  content: '';
  background-image: url("images/arrow_right.png");
  margin-right: 26px;
  background-size: 13px 18px;
  background-repeat: no-repeat;
  background-position: center;
  display: inline-block;
  width: 13px;
  height: 18px;
}
.wrapper .container .left ul li:first-child a,
.wrapper .container .left ul li:nth-child(6) a {
  cursor: default;
}
.wrapper .container .left ul li:first-child:hover,
.wrapper .container .left ul li:nth-child(6):hover {
  background-color: transparent;
}
.wrapper .container .left ul li:first-child::before {
  visibility: hidden;
}
.wrapper .container .right {
  border-left: 1px solid white;
  box-sizing: border-box;
}
.wrapper .container .right ul {
  list-style: none;
}
.wrapper .container .right ul li::before {
  content: '';
  background-image: url("images/arrow_right.png");
  margin-right: 26px;
  background-size: 13px 18px;
  background-repeat: no-repeat;
  background-position: center;
  display: inline-block;
  width: 13px;
  height: 18px;
}
.wrapper .container .right ul li:first-child a,
.wrapper .container .right ul li:nth-child(5) a,
.wrapper .container .right ul li:nth-child(6) a {
  cursor: default;
}
.wrapper .container .right ul li:first-child:hover,
.wrapper .container .right ul li:nth-child(5):hover,
.wrapper .container .right ul li:nth-child(6):hover {
  background-color: transparent;
}
.wrapper .container .right ul li:first-child::before {
  visibility: hidden;
}
.wrapper .container .footer,
.wrapper .container .info {
  width: 100%;
  height: 15%;
  border-top: 2px solid white;
  box-sizing: border-box;
  color: white;
}
.wrapper .container .info {
  display: flex;
}
.wrapper .container .info .info-l,
.wrapper .container .info .info-r {
  display: flex;
  align-items: center;
  width: 50%;
  height: 100%;
  box-sizing: border-box;
}
.wrapper .container .info .info-l ul,
.wrapper .container .info .info-r ul {
  list-style: none;
  padding-left: 20px;
}
.wrapper .container .info .info-r {
  padding-left: 20px;
}
.wrapper .container .info .info-r p {
  padding-left: 10px;
}
.wrapper .container .info .info-r i {
  margin: 0 6px;
}
.wrapper .container .footer {
  display: flex;
  justify-content: center;
  align-items: center;
}
.wrapper .container .footer p {
  margin: 0;
}

@media only screen and (max-width: 1366px) {
  .wrapper h1 {
    font-size: 1.7em;
  }
  .wrapper .container {
    font-size: 1.7em;
  }
  .wrapper .container .left,
  .wrapper .container .right {
    padding-left: 1%;
  }
  .wrapper .container .left ul li,
  .wrapper .container .right ul li {
    padding: 12px 15px;
  }
}
@media only screen and (max-width: 1024px) {
  body,
  html {
    height: auto;
    overflow-y: auto;
  }

  .wrapper {
    height: auto;
    overflow-y: scroll;
  }
  .wrapper h1 {
    font-size: 1.3em;
    margin-bottom: 30px;
  }
  .wrapper .container {
    font-size: 1.3em;
    height: auto;
  }
  .wrapper .container .left,
  .wrapper .container .right {
    width: 100%;
    height: auto;
    padding: 20px;
  }
  .wrapper .container .left ul,
  .wrapper .container .right ul {
    margin-top: 0;
    margin-bottom: 0;
  }
  .wrapper .container .left ul li,
  .wrapper .container .right ul li {
    padding: 10px 0;
  }
  .wrapper .container .left ul li:first-child::before,
  .wrapper .container .right ul li:first-child::before {
    display: none;
  }
  .wrapper .container .left {
    border: none;
    border-bottom: 1px solid white;
  }
  .wrapper .container .left ul li::before {
    margin-right: 10px;
    background-size: 10px 15px;
    width: 10px;
    height: 15px;
  }
  .wrapper .container .right {
    border: none;
  }
  .wrapper .container .right ul li::before {
    margin-right: 10px;
    background-size: 10px 15px;
    width: 10px;
    height: 15px;
  }
  .wrapper .container .footer,
  .wrapper .container .info {
    height: auto;
  }
  .wrapper .container .info {
    flex-direction: column;
    padding: 0 20px;
  }
  .wrapper .container .info .info-l,
  .wrapper .container .info .info-r {
    width: 100%;
    height: auto;
    padding-left: 1%;
  }
  .wrapper .container .info .info-l ul,
  .wrapper .container .info .info-r ul {
    padding-left: 0;
    margin-bottom: 0;
  }
  .wrapper .container .info .info-r p {
    padding-left: 0;
  }
  .wrapper .container .info .info-r i {
    font-size: 18px;
  }
  .wrapper .container .footer {
    display: none;
  }
}
body {
  counter-reset: counter;
}

.wrapper .discography {
  display: flex;
  text-align: left;
  padding: 50px 0 0 60px;
  position: relative;
  overflow: hidden;
}
.wrapper .discography a {
  text-decoration: none;
}
.wrapper .discography a:hover {
  background-color: rgba(255, 255, 0, 0.1);
}
.wrapper .discography .disc-box {
  box-sizing: border-box;
  width: 50%;
  height: 88%;
  overflow-y: scroll;
}
.wrapper .discography .disc-box h1 {
  text-align: left;
  font-size: 1.475em;
  color: white;
  font-weight: normal;
  margin: 0;
}
.wrapper .discography .disc-box p,
.wrapper .discography .disc-box ul {
  color: white;
}
.wrapper .discography .disc-box p {
  margin-bottom: 0;
}
.wrapper .discography .disc-box ul {
  list-style: none;
  padding-left: 0;
  margin: 0;
}
.wrapper .discography .disc-box .tracklist li {
  counter-increment: counter;
}
.wrapper .discography .disc-box .tracklist li:before {
  content: counter(counter) ". ";
}
.wrapper .discography .disc-box .cmos {
  margin-bottom: 0;
}
.wrapper .discography .disc-box a {
  text-decoration: none;
}
.wrapper .discography .disc-box a:hover {
  background-color: rgba(255, 255, 0, 0.1);
}
.wrapper .discography .album-cover-box {
  width: 50%;
  height: 88%;
  padding: 0 20px;
  box-sizing: border-box;
  text-align: center;
}
.wrapper .discography .album-cover-box img {
  max-width: 70%;
  height: auto;
}
.wrapper .discography .back {
  position: absolute;
  bottom: 10px;
}

@media only screen and (max-width: 1024px) {
  .wrapper .discography .disc-box {
    height: auto;
  }
  .wrapper .discography .album-cover-box {
    height: auto;
  }
  .wrapper .discography .album-cover-box img {
    max-width: 90%;
  }
  .wrapper .discography .back {
    position: relative;
    margin-top: 20px;
    bottom: auto;
  }
}
@media only screen and (max-width: 810px) {
  .wrapper .discography {
    flex-direction: column;
    padding: 20px;
  }
  .wrapper .discography .disc-box {
    width: 100%;
  }
  .wrapper .discography .disc-box h1 {
    font-size: 1.175em;
  }
  .wrapper .discography .album-cover-box {
    width: 100%;
    padding: 20px 0;
  }
  .wrapper .discography .album-cover-box img {
    max-width: 100%;
    width: 100%;
  }
}
.wrapper .about {
  flex-direction: column;
  text-align: left;
  padding: 50px 0 0 60px;
  position: relative;
}
.wrapper .about p {
  color: white;
  margin-top: 0;
}
.wrapper .about a {
  text-decoration: none;
}

@media only screen and (max-width: 1024px) {
  .wrapper .about {
    padding: 20px;
  }
  .wrapper .about p:last-child {
    margin-bottom: 0;
  }
}
.wrapper .tour {
  flex-direction: column;
  text-align: left;
  padding: 50px 0 0 60px;
  position: relative;
}
.wrapper .tour p {
  color: white;
  margin-top: 0;
}
.wrapper .tour p:last-child {
  margin-bottom: 0;
}
.wrapper .tour a {
  text-decoration: none;
}

@media only screen and (max-width: 1024px) {
  .wrapper .tour {
    padding: 20px;
  }
}
.wrapper .contact {
  flex-direction: column;
  text-align: left;
  padding: 50px 0 0 60px;
  position: relative;
}
.wrapper .contact p {
  color: white;
  text-align: left;
  margin: 0;
}
.wrapper .contact p:last-child {
  margin-top: 20px;
}
.wrapper .contact .email {
  margin-bottom: 22px;
}
.wrapper .contact a {
  text-decoration: none;
}

@media only screen and (max-width: 1024px) {
  .wrapper .contact {
    padding: 20px;
  }
  .wrapper .contact .email a {
    word-wrap: anywhere;
  }
  .wrapper .contact p:last-child {
    margin-bottom: 0;
  }
}
